<template>
  <div class="about-us">
    <div class="round-right"></div>
    <div class="round-left"></div>
    <div class="container product-page">
      <div class="product-block-title">
        RentMate - система учета и автоматизации аренды коммерческой недвижимости
      </div>
      <div class="product-block-desc colored-back">
        Управляйте арендой без ежедневной рутины. Освободите до 30% времени сотрудников
      </div>

      <div class="product-block-subtitle" style="margin-top: 0">
        Проблемы
      </div>
      <div class="product-problems">
        <div class="product-problem">
          <div class="product-problem-title">
            1. Отсутствие статистики
          </div>
          <div class="product-problem-text">
            Заполняемость помещений, задолженность, приток и отток арендаторов, динамика и прогноз доходов собираются по запросу или не ведутся вообще
          </div>
        </div>
        <div class="product-problem">
          <div class="product-problem-title">
            3. Доступность данных
          </div>
          <div class="product-problem-text">
            Аренда оторвана от отдела продаж. Арендатор не может посмотреть какие помещения свободны, какие заняты, посмотреть какую-либо
            информацию по ним
          </div>
        </div>
        <div class="product-problem">
          <div class="product-problem-title">
            2. Время
          </div>
          <div class="product-problem-text">
            30% времени управляющих, бухгалтеров и юристов уходит
            на рутинные задачи
          </div>
        </div>
        <div class="product-problem">
          <div class="product-problem-title">
            4. Человеческий фактор
          </div>
          <div class="product-problem-text">
            Человеческий фактор - просроченный договор, не обработанная заявка, поздно выставленный счет на оплату или акт - приводят к доп. затратам денег и времени
          </div>
        </div>
      </div>

      <el-image
          style="width: 100%; height: 100%"
          src="/css/images/products/rentmate-back1.png"
          fit="fit"></el-image>

      <div class="product-block-subtitle">
        Решение
      </div>
      <ul class="product-block-skills">
        <li v-for="solution in solutions" style="text-indent: -13px; padding-left: 20px">
          {{ solution }}
        </li>
      </ul>

      <el-image
          style="width: 100%; height: 100%"
          src="/css/images/products/rentmate-back2.png"
          fit="fit"></el-image>

      <div class="product-block-subtitle">
        Возможности системы
      </div>
      <ul class="product-block-skills">
        <li v-for="capability in capabilities" style="text-indent: -13px; padding-left: 20px">
          {{ capability }}
        </li>
      </ul>

      <el-image
          style="width: 100%; height: 100%"
          src="/css/images/products/rentmate-back3.png"
          fit="fit"></el-image>

      <div class="product-banner">
        Хочешь управлять недвижимостью без рутины?
        <div class="product-banner-join">Присоединяйся к нам!</div>
      </div>

    </div>
  </div>
</template>


<script>

import Vue from "vue";

export default {
  name: 'Products',
  data() {
    return {
      currentTab: "all",
      solutions: [
        'Освобождает 30% времени управляющих, бухгалтеров и юристов',
        'Формирует статистику по занятости каждого здания',
        'Показывает занятые и свободные помещения на интерактивных планах этажей',
        'Помогает отслеживать дебиторскую задолжность',
      ],
      capabilities: [
        'Формирование договоров аренды',
        'Заявки по тех обслуживанию помещений',
        'Контроль занятости помещений',
        'Заявки на аренду от потенциальных арендаторов',
        'Выставление счетов и актов',
        'Отчеты и дашборд',
        'Отслеживание дебиторской задолженности',
        'Интеграции/Синхронизация с С1, банками, CRM, API, виджеты',
        'Интерактивные планы этажей',
      ],
    }
  },
}
</script>
<style>

</style>
